<template>
  <div class="border-b">
    <lf-h4>
      {{ $t("COMMON.WORKFLOWS") }}
    </lf-h4>
    <div class="pt-4">
      <search-input
        v-model="search"
        no-padding
        no-margin
        name="searchWorkflowTemplates"
        :search-term="$t('COMMON.WORKFLOWS')"
      />
    </div>
    <div class="flex w-full justify-between p-4 border-x">
      <workflow-category-filter
        class="w-1/3 pr-4"
        v-model="selectedWorkflowCategoryIds"
      />
      <lf-table
        class="w-full h-100"
        :class="loading ? 'border' : 'border-x border-b'"
        head-classes="border-0"
        select-all
        header-sticky
        :columns="workflowColumns"
        :data="workflowTemplateResource.data"
        :row-with-border="false"
        :has-border="false"
        :is-table-loading="loading"
        :checked-rows="modelValue"
        @selected-rows="handleTemplateSelection"
        @change-page="changePage"
        @change-items-per-page="changePerPage"
        :disabled-checkboxes="disabled"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch, type PropType } from "vue";
import { useI18n } from "vue-i18n";
import compact from "lodash/compact";
import { useAuth } from "@/hooks/auth";
import { DEFAULT_PER_PAGE } from "@/helpers/constants";
import type { WorkflowsViewFilters } from "@/models/workflows";
import type { IWorkflow } from "@/models/workflows";
import type { IDealsColumn } from "@/models/applications";
import type { IClient } from "@/models/clients";
import type {
  IPaginatedResponse,
  IResponseLinks,
  IResponseMeta,
  Nullish
} from "@/models/common";
import WorkflowCategoryFilter from "@/components/presets/presetModal/WorkflowCategoryFilter.vue";
import difference from "lodash/difference";
import uniq from "lodash/uniq";
import LfTable from "@/components/ui/table/LfTable.vue";
import workflowApiService from "@/services/modules/workflows";

const emit = defineEmits<{
  (eventName: "update:modelValue", id: string[]): void;
}>();

const props = defineProps({
  modelValue: {
    type: Array as PropType<string[]>,
    default: () => []
  },
  assignedClientIds: {
    type: Array as PropType<string[]>,
    default: () => []
  },
  presetWfbTemplates: {
    type: Array as PropType<IWorkflow[]>,
    default: () => []
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

const { isLendflowUser } = useAuth();
const { t } = useI18n();

const search = ref("");
const loading = ref(false);
const templateToClientMap = ref<
  Record<IWorkflow["id"], IClient["id"] | Nullish>
>({});
const selectedWorkflowCategoryIds = ref<number[]>([]);
const workflowTemplateResource = ref<IPaginatedResponse<IWorkflow>>({
  data: [],
  links: {} as IResponseLinks,
  meta: {} as IResponseMeta
});

const pagination = ref({
  page: 0,
  per_page: DEFAULT_PER_PAGE
});

const workflowColumns: Array<IDealsColumn> = compact([
  {
    key: "name",
    label: t("COMMON.TEMPLATE_NAME")
  },
  {
    key: "category",
    label: t("WORKFLOW.STEPS.CATEGORY"),
    component: "workflow-category-pill"
  },
  isLendflowUser && {
    key: "client.name",
    label: t("COMMON.CLIENT")
  }
]);

const getWorkflowTemplates = async () => {
  loading.value = true;

  const params: WorkflowsViewFilters = {
    sort_by: "-updated_at",
    search: search.value,
    clients: props.assignedClientIds || null,
    categories: selectedWorkflowCategoryIds.value,
    approved: 1,
    active: 1,
    page: pagination.value.page,
    per_page: pagination.value.per_page,
    global: 1
  };

  const response = await workflowApiService.filterWorkflowTemplates(params);

  workflowTemplateResource.value = response;
  loading.value = false;
};

const changePage = (newPage: number) => {
  pagination.value.page = newPage;
  getWorkflowTemplates();
};

const changePerPage = (newPerPage: number, page: number) => {
  if (page) {
    pagination.value.page = page;
  }
  pagination.value.per_page = newPerPage;
  getWorkflowTemplates();
};

const handleTemplateSelection = (
  selectedTemplateIds: string[],
  isSelected: boolean | undefined
) => {
  workflowTemplateResource.value.data
    .filter((template) => selectedTemplateIds.includes(template.id))
    .forEach((template) => {
      templateToClientMap.value[template.id] = template.client_id;
    });

  if (typeof isSelected === "undefined") {
    emit("update:modelValue", selectedTemplateIds);
    return;
  }

  if (isSelected) {
    emit(
      "update:modelValue",
      uniq([...props.modelValue, ...selectedTemplateIds])
    );
    return;
  }

  const allTableIds = workflowTemplateResource.value.data.map(({ id }) => id);
  emit("update:modelValue", difference(props.modelValue, allTableIds));
};

const removeInvalidTemplates = (clientIds: string[]) => {
  const validTemplates = props.modelValue.filter((templateId) => {
    const clientId = templateToClientMap.value[templateId];
    return (
      clientId === null ||
      (typeof clientId === "string" && clientIds.includes(clientId))
    );
  });
  emit("update:modelValue", validTemplates);
};

watch(
  [() => props.assignedClientIds, search, selectedWorkflowCategoryIds],
  ([newAssignedClientIds], [oldAssignedClientIds]) => {
    if (newAssignedClientIds.length < oldAssignedClientIds.length) {
      removeInvalidTemplates(newAssignedClientIds);
    }
    getWorkflowTemplates();
  },
  { deep: true }
);

onMounted(() => {
  getWorkflowTemplates();
  props.presetWfbTemplates.forEach((template) => {
    if (template.id) {
      templateToClientMap.value[template.id] = template.client_id;
    }
  });
});
</script>
